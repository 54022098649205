import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./config";

export const saveCateringSurvey = async (surveyData) => {
  try {
    const docRef = await addDoc(collection(db, "catering-surveys"), {
      ...surveyData,
      createdAt: serverTimestamp(),
    });
    return { success: true, id: docRef.id };
  } catch (error) {
    console.error("Error saving survey:", error);
    return { success: false, error: error.message };
  }
};
