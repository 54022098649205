import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { saveCateringSurvey } from "../firebase/cateringService";
import { CheckCircle } from "lucide-react";

const CateringSurveyPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [countdown, setCountdown] = useState(5);

  // State for ingredients
  const [ingredients, setIngredients] = useState([
    { name: "", quantity: "", unit: "", source: "" },
  ]);

  // State for equipment
  const [equipment, setEquipment] = useState([
    { type: "", energyType: "", usageHours: "" },
  ]);

  // State for refrigeration
  const [refrigerationItems, setRefrigerationItems] = useState([
    { type: "", coolantAge: "", refrigerantType: "" },
  ]);

  // State for delivery vehicles
  const [deliveryVehicles, setDeliveryVehicles] = useState([
    { type: "", fuelType: "", distance: "", trips: "" },
  ]);

  // State for packaging
  const [packagingItems, setPackagingItems] = useState([
    { type: "", quantity: "", material: "", disposal: "" },
  ]);

  // State for electricity consumption
  const [electricityData, setElectricityData] = useState({
    hasMeter: "",
    exactUsage: "", // For exact measurements
    estimatedUsage: "", // For estimates
  });

  // State for waste management
  const [wasteData, setWasteData] = useState({
    foodWaste: "",
    foodWasteUnit: "kg",
    disposalMethod: "",
  });

  // Add countdown effect after successful submission
  useEffect(() => {
    if (isSuccess && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (isSuccess && countdown === 0) {
      navigate("/");
    }
  }, [isSuccess, countdown, navigate]);

  // Generic add item handler
  const addItem = (setter, initialState) => {
    setter((prev) => [...prev, initialState]);
  };

  // Generic remove item handler
  const removeItem = (setter, index) => {
    setter((prev) => prev.filter((_, i) => i !== index));
  };

  // Generic change handler
  const handleItemChange = (setter, index, field, value) => {
    setter((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    const validationErrors = [];

    // Check ingredients
    if (
      ingredients.some(
        (ing) => !ing.name || !ing.quantity || !ing.unit || !ing.source
      )
    ) {
      validationErrors.push("Please fill in all ingredient fields");
    }

    // Check equipment (only type and energyType are required)
    if (equipment.some((eq) => !eq.type || !eq.energyType)) {
      validationErrors.push("Please fill in equipment type and energy type");
    }

    // Check refrigeration (only type and coolantAge are required)
    if (refrigerationItems.some((ref) => !ref.type || !ref.coolantAge)) {
      validationErrors.push(
        "Please fill in refrigeration type and coolant age"
      );
    }

    // Check delivery vehicles
    if (
      deliveryVehicles.some(
        (veh) => !veh.type || !veh.fuelType || !veh.distance || !veh.trips
      )
    ) {
      validationErrors.push("Please fill in all delivery vehicle fields");
    }

    // Check packaging
    if (
      packagingItems.some(
        (pack) =>
          !pack.type || !pack.quantity || !pack.material || !pack.disposal
      )
    ) {
      validationErrors.push("Please fill in all packaging fields");
    }

    // Check electricity data
    if (!electricityData.hasMeter) {
      validationErrors.push("Please select an electricity measurement option");
    }
    if (electricityData.hasMeter === "yes" && !electricityData.exactUsage) {
      validationErrors.push("Please enter exact electricity usage");
    }
    if (
      electricityData.hasMeter === "estimate" &&
      !electricityData.estimatedUsage
    ) {
      validationErrors.push("Please enter estimated electricity usage");
    }

    // Check waste data
    if (!wasteData.foodWaste || !wasteData.disposalMethod) {
      validationErrors.push("Please fill in all waste management fields");
    }

    if (validationErrors.length > 0) {
      setSubmitError(validationErrors.join(". "));
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    // Continue with form submission if validation passes
    setIsSubmitting(true);
    setSubmitError(null);

    const surveyData = {
      ingredients,
      equipment,
      refrigerationItems,
      deliveryVehicles,
      packagingItems,
      electricityData,
      wasteData,
    };

    try {
      const result = await saveCateringSurvey(surveyData);
      if (result.success) {
        setIsSuccess(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setSubmitError("Failed to submit survey. Please try again.");
      }
    } catch (error) {
      setSubmitError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSuccess) {
    return (
      <section className="min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-100 to-white py-16">
        <div className="max-w-md w-full mx-auto text-center px-4">
          <div className="bg-white rounded-xl shadow-lg p-8">
            <div className="mb-6">
              <CheckCircle className="w-16 h-16 text-[#359381] mx-auto mb-4" />
              <h2 className="text-2xl font-bold text-[#003329] mb-2">
                Thank You!
              </h2>
              <p className="text-gray-600 mb-4">
                Your catering carbon footprint survey has been successfully
                submitted.
              </p>
              <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
                <div
                  className="bg-[#359381] h-2 rounded-full transition-all duration-1000"
                  style={{ width: `${(countdown / 5) * 100}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-500">
                Redirecting to homepage in {countdown} seconds...
              </p>
            </div>
            <button
              onClick={() => navigate("/")}
              className="bg-[#359381] text-white px-6 py-2 rounded-lg font-medium hover:bg-[#003329] transition-colors"
            >
              Return Home Now
            </button>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-16 bg-gradient-to-b from-gray-100 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold bg-gradient-to-r from-[#359381] to-[#359381] bg-clip-text text-transparent mb-4">
              Catering Event Carbon Calculator
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Thank you for helping us assess your event's carbon footprint.
              Please fill out the survey with as much accurate information as
              possible. If you're unsure about certain details, an estimate is
              excellent.
            </p>
          </div>

          <div className="text-sm text-gray-600 mb-8">
            Fields marked with <span className="text-red-500">*</span> are
            required
          </div>

          <form
            onSubmit={handleSubmit}
            className="bg-white rounded-xl shadow-sm p-8"
          >
            {/* SECTION 1: INGREDIENTS */}
            <section className="mb-12">
              <div className="flex items-center mb-6">
                <span className="w-10 h-10 flex items-center justify-center bg-[#359381] text-white rounded-full mr-4 font-semibold">
                  1
                </span>
                <h2 className="text-2xl font-semibold text-[#003329]">
                  Ingredients
                </h2>
              </div>

              <p className="text-gray-600 mb-6">
                List all ingredients used in your catering operation. Include
                their quantities and source to help us calculate the carbon
                footprint of your food items.
              </p>

              {ingredients.map((ingredient, index) => (
                <div
                  key={index}
                  className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-4"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[#003329]">
                      Ingredient #{index + 1}
                    </h4>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeItem(setIngredients, index)}
                        className="text-red-500 hover:text-red-700 transition-colors"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Name <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        value={ingredient.name}
                        onChange={(e) =>
                          handleItemChange(
                            setIngredients,
                            index,
                            "name",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        required
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Quantity & Unit <span className="text-red-500">*</span>
                      </span>
                      <div className="flex gap-2">
                        <input
                          type="number"
                          min="0"
                          value={ingredient.quantity}
                          onChange={(e) =>
                            handleItemChange(
                              setIngredients,
                              index,
                              "quantity",
                              e.target.value
                            )
                          }
                          className="w-2/3 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                          required
                        />
                        <input
                          type="text"
                          value={ingredient.unit}
                          onChange={(e) =>
                            handleItemChange(
                              setIngredients,
                              index,
                              "unit",
                              e.target.value
                            )
                          }
                          placeholder="kg/L/units"
                          className="w-1/3 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                          required
                        />
                      </div>
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Source/Supplier <span className="text-red-500">*</span>
                      </span>
                      <select
                        value={ingredient.source}
                        onChange={(e) =>
                          handleItemChange(
                            setIngredients,
                            index,
                            "source",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        required
                      >
                        <option value="">Select source</option>
                        <option value="imported">Imported</option>
                        <option value="local">Locally Sourced</option>
                        <option value="mixed">Mixed</option>
                      </select>
                    </label>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() =>
                  addItem(setIngredients, {
                    name: "",
                    quantity: "",
                    unit: "",
                    source: "",
                  })
                }
                className="mt-4 inline-flex items-center px-4 py-2 border border-[#359381] text-[#359381] rounded-lg hover:bg-[#359381] hover:text-white transition-colors"
              >
                <span className="mr-2">+</span> Add Another Ingredient
              </button>
            </section>

            {/* SECTION 2: EQUIPMENT */}
            <section className="mb-12">
              <div className="flex items-center mb-6">
                <span className="w-10 h-10 flex items-center justify-center bg-[#359381] text-white rounded-full mr-4 font-semibold">
                  2
                </span>
                <h2 className="text-2xl font-semibold text-[#003329]">
                  Equipment
                </h2>
              </div>

              <p className="text-gray-600 mb-6">
                Detail all cooking and food preparation equipment used. Include
                both electrical and gas-powered equipment, along with their
                usage duration for accurate energy calculations.
              </p>

              {equipment.map((item, index) => (
                <div
                  key={index}
                  className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-4"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[#003329]">
                      Equipment #{index + 1}
                    </h4>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeItem(setEquipment, index)}
                        className="text-red-500 hover:text-red-700 transition-colors"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Type <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        required
                        value={item.type}
                        onChange={(e) =>
                          handleItemChange(
                            setEquipment,
                            index,
                            "type",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="e.g., Oven, Stove"
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Energy Type <span className="text-red-500">*</span>
                      </span>
                      <select
                        value={item.energyType}
                        onChange={(e) =>
                          handleItemChange(
                            setEquipment,
                            index,
                            "energyType",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        required
                      >
                        <option value="">Select energy type</option>
                        <option value="electricity">Electricity</option>
                        <option value="gas">Gas</option>
                      </select>
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Usage Hours
                      </span>
                      <input
                        type="number"
                        min="0"
                        value={item.usageHours}
                        onChange={(e) =>
                          handleItemChange(
                            setEquipment,
                            index,
                            "usageHours",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="Hours for the event"
                      />
                    </label>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() =>
                  addItem(setEquipment, {
                    type: "",
                    energyType: "",
                    usageHours: "",
                  })
                }
                className="mt-4 inline-flex items-center px-4 py-2 border border-[#359381] text-[#359381] rounded-lg hover:bg-[#359381] hover:text-white transition-colors"
              >
                <span className="mr-2">+</span> Add Another Equipment
              </button>
            </section>

            {/* SECTION 3: REFRIGERATION */}
            <section className="mb-12">
              <div className="flex items-center mb-6">
                <span className="w-10 h-10 flex items-center justify-center bg-[#359381] text-white rounded-full mr-4 font-semibold">
                  3
                </span>
                <h2 className="text-2xl font-semibold text-[#003329]">
                  Refrigeration
                </h2>
              </div>

              <p className="text-gray-600 mb-6">
                Please provide details about any refrigeration units used during
                food preparation and storage. Include all fridges, freezers, and
                cooling units, along with their coolant information if
                available.
              </p>

              {refrigerationItems.map((item, index) => (
                <div
                  key={index}
                  className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-4"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[#003329]">
                      Refrigeration Unit #{index + 1}
                    </h4>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeItem(setRefrigerationItems, index)}
                        className="text-red-500 hover:text-red-700 transition-colors"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Type <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        value={item.type}
                        onChange={(e) =>
                          handleItemChange(
                            setRefrigerationItems,
                            index,
                            "type",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="e.g., Walk-in Freezer, Display Fridge"
                        required
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Coolant Age (years){" "}
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="number"
                        min="0"
                        value={item.coolantAge}
                        onChange={(e) =>
                          handleItemChange(
                            setRefrigerationItems,
                            index,
                            "coolantAge",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="e.g., 2"
                        required
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Refrigerant Type
                      </span>
                      <input
                        type="text"
                        value={item.refrigerantType}
                        onChange={(e) =>
                          handleItemChange(
                            setRefrigerationItems,
                            index,
                            "refrigerantType",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="e.g., R-134a, R-404A"
                      />
                    </label>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() =>
                  addItem(setRefrigerationItems, {
                    type: "",
                    coolantAge: "",
                    refrigerantType: "",
                  })
                }
                className="mt-4 inline-flex items-center px-4 py-2 border border-[#359381] text-[#359381] rounded-lg hover:bg-[#359381] hover:text-white transition-colors"
              >
                <span className="mr-2">+</span> Add Another Refrigeration Unit
              </button>
            </section>

            {/* SECTION 4: DELIVERY VEHICLES */}
            <section className="mb-12">
              <div className="flex items-center mb-6">
                <span className="w-10 h-10 flex items-center justify-center bg-[#359381] text-white rounded-full mr-4 font-semibold">
                  4
                </span>
                <h2 className="text-2xl font-semibold text-[#003329]">
                  Delivery Vehicles
                </h2>
              </div>

              <p className="text-gray-600 mb-6">
                Provide information about all vehicles used for ingredient
                sourcing and food delivery. Include details about distance
                traveled and number of trips to help calculate transportation
                emissions.
              </p>

              {deliveryVehicles.map((vehicle, index) => (
                <div
                  key={index}
                  className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-4"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[#003329]">
                      Vehicle #{index + 1}
                    </h4>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeItem(setDeliveryVehicles, index)}
                        className="text-red-500 hover:text-red-700 transition-colors"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Type <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        value={vehicle.type}
                        onChange={(e) =>
                          handleItemChange(
                            setDeliveryVehicles,
                            index,
                            "type",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="e.g., Van, Truck"
                        required
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Fuel Type <span className="text-red-500">*</span>
                      </span>
                      <select
                        value={vehicle.fuelType}
                        onChange={(e) =>
                          handleItemChange(
                            setDeliveryVehicles,
                            index,
                            "fuelType",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        required
                      >
                        <option value="">Select fuel type</option>
                        <option value="petrol">Petrol</option>
                        <option value="diesel">Diesel</option>
                        <option value="electric">Electric</option>
                        <option value="hybrid">Hybrid</option>
                      </select>
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Distance (km) <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="number"
                        min="0"
                        value={vehicle.distance}
                        onChange={(e) =>
                          handleItemChange(
                            setDeliveryVehicles,
                            index,
                            "distance",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="Total distance"
                        required
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Number of Trips <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="number"
                        min="0"
                        value={vehicle.trips}
                        onChange={(e) =>
                          handleItemChange(
                            setDeliveryVehicles,
                            index,
                            "trips",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="Number of trips for the event"
                        required
                      />
                    </label>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() =>
                  addItem(setDeliveryVehicles, {
                    type: "",
                    fuelType: "",
                    distance: "",
                    trips: "",
                  })
                }
                className="mt-4 inline-flex items-center px-4 py-2 border border-[#359381] text-[#359381] rounded-lg hover:bg-[#359381] hover:text-white transition-colors"
              >
                <span className="mr-2">+</span> Add Another Vehicle
              </button>
            </section>

            {/* SECTION 5: PACKAGING */}
            <section className="mb-12">
              <div className="flex items-center mb-6">
                <span className="w-10 h-10 flex items-center justify-center bg-[#359381] text-white rounded-full mr-4 font-semibold">
                  5
                </span>
                <h2 className="text-2xl font-semibold text-[#003329]">
                  Packaging
                </h2>
              </div>

              <p className="text-gray-600 mb-6">
                List all packaging materials used in your catering service,
                including food containers, wrapping materials, and disposable
                items. This helps us assess the environmental impact of your
                packaging choices.
              </p>

              {packagingItems.map((item, index) => (
                <div
                  key={index}
                  className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-4"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[#003329]">
                      Packaging Item #{index + 1}
                    </h4>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeItem(setPackagingItems, index)}
                        className="text-red-500 hover:text-red-700 transition-colors"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Type <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        value={item.type}
                        onChange={(e) =>
                          handleItemChange(
                            setPackagingItems,
                            index,
                            "type",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="e.g., Container, Bag"
                        required
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Quantity <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="number"
                        min="0"
                        value={item.quantity}
                        onChange={(e) =>
                          handleItemChange(
                            setPackagingItems,
                            index,
                            "quantity",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="Number of items"
                        required
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Material <span className="text-red-500">*</span>
                      </span>
                      <select
                        value={item.material}
                        onChange={(e) =>
                          handleItemChange(
                            setPackagingItems,
                            index,
                            "material",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        required
                      >
                        <option value="">Select material</option>
                        <option value="plastic">Plastic</option>
                        <option value="paper">Paper</option>
                        <option value="cardboard">Cardboard</option>
                        <option value="aluminum">Aluminum</option>
                        <option value="glass">Glass</option>
                        <option value="biodegradable">Biodegradable</option>
                      </select>
                    </label>
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Disposal Method <span className="text-red-500">*</span>
                      </span>
                      <select
                        value={item.disposal}
                        onChange={(e) =>
                          handleItemChange(
                            setPackagingItems,
                            index,
                            "disposal",
                            e.target.value
                          )
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        required
                      >
                        <option value="">Select disposal method</option>
                        <option value="recycling">Recycling</option>
                        <option value="composting">Composting</option>
                        <option value="landfill">Landfill</option>
                        <option value="other">Other</option>
                      </select>
                      {item.disposal === "other" && (
                        <input
                          type="text"
                          placeholder="Please specify disposal method"
                          value={item.disposalOther || ""}
                          onChange={(e) =>
                            handleItemChange(
                              setPackagingItems,
                              index,
                              "disposalOther",
                              e.target.value
                            )
                          }
                          className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        />
                      )}
                    </label>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() =>
                  addItem(setPackagingItems, {
                    type: "",
                    quantity: "",
                    material: "",
                    disposal: "",
                  })
                }
                className="mt-4 inline-flex items-center px-4 py-2 border border-[#359381] text-[#359381] rounded-lg hover:bg-[#359381] hover:text-white transition-colors"
              >
                <span className="mr-2">+</span> Add Another Packaging Item
              </button>
            </section>

            {/* SECTION 6: ELECTRICITY CONSUMPTION */}
            <section className="mb-12">
              <div className="flex items-center mb-6">
                <span className="w-10 h-10 flex items-center justify-center bg-[#359381] text-white rounded-full mr-4 font-semibold">
                  6
                </span>
                <h2 className="text-2xl font-semibold text-[#003329]">
                  Electricity Consumption
                </h2>
              </div>

              <p className="text-gray-600 mb-6">
                Help us understand your electricity usage during the catering
                preparation. If you have access to meter readings or utility
                data specific to the catering operation, please provide those
                details below.
              </p>

              <div className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-4">
                <label className="block mb-4">
                  <span className="text-gray-700 font-medium mb-2 block">
                    Can you measure or access the specific electricity
                    consumption for the catering preparation day?{" "}
                    <span className="text-red-500">*</span>
                  </span>
                  <select
                    value={electricityData.hasMeter}
                    onChange={(e) =>
                      setElectricityData((prev) => ({
                        ...prev,
                        hasMeter: e.target.value,
                      }))
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="yes">
                      Yes, I can provide exact consumption
                    </option>
                    <option value="estimate">
                      No, but I can estimate the usage
                    </option>
                    <option value="no">No, and I cannot estimate</option>
                  </select>
                </label>

                {electricityData.hasMeter === "yes" && (
                  <div className="mt-4">
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Exact Electricity Usage (kWh){" "}
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="number"
                        min="0"
                        value={electricityData.exactUsage}
                        onChange={(e) =>
                          setElectricityData((prev) => ({
                            ...prev,
                            exactUsage: e.target.value,
                          }))
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="Enter exact electricity consumption"
                        required
                      />
                    </label>
                  </div>
                )}

                {electricityData.hasMeter === "estimate" && (
                  <div className="mt-4">
                    <label className="block">
                      <span className="text-gray-700 font-medium mb-2 block">
                        Estimated Electricity Usage (kWh){" "}
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="number"
                        min="0"
                        value={electricityData.estimatedUsage}
                        onChange={(e) =>
                          setElectricityData((prev) => ({
                            ...prev,
                            estimatedUsage: e.target.value,
                          }))
                        }
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="Enter your estimated usage"
                        required
                      />
                    </label>
                  </div>
                )}
              </div>
            </section>

            {/* SECTION 7: WASTE MANAGEMENT */}
            <section className="mb-12">
              <div className="flex items-center mb-6">
                <span className="w-10 h-10 flex items-center justify-center bg-[#359381] text-white rounded-full mr-4 font-semibold">
                  7
                </span>
                <h2 className="text-2xl font-semibold text-[#003329]">
                  Waste Management
                </h2>
              </div>

              <p className="text-gray-600 mb-6">
                Detail how food waste and packaging materials are disposed of
                after the catering service. This information helps us understand
                the end-of-life impact of your operation.
              </p>

              <div className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-4">
                <div className="grid md:grid-cols-2 gap-4">
                  <label className="block">
                    <span className="text-gray-700 font-medium mb-2 block">
                      Food Waste Amount <span className="text-red-500">*</span>
                    </span>
                    <div className="flex gap-2">
                      <input
                        type="number"
                        min="0"
                        value={wasteData.foodWaste}
                        onChange={(e) =>
                          setWasteData((prev) => ({
                            ...prev,
                            foodWaste: e.target.value,
                          }))
                        }
                        className="w-2/3 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                        placeholder="Enter amount"
                        required
                      />
                      <select
                        value={wasteData.foodWasteUnit}
                        onChange={(e) =>
                          setWasteData((prev) => ({
                            ...prev,
                            foodWasteUnit: e.target.value,
                          }))
                        }
                        className="w-1/3 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                      >
                        <option value="kg">kg</option>
                        <option value="%">%</option>
                      </select>
                    </div>
                  </label>

                  <label className="block">
                    <span className="text-gray-700 font-medium mb-2 block">
                      Disposal Method <span className="text-red-500">*</span>
                    </span>
                    <select
                      value={wasteData.disposalMethod}
                      onChange={(e) =>
                        setWasteData((prev) => ({
                          ...prev,
                          disposalMethod: e.target.value,
                        }))
                      }
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#359381] focus:border-transparent transition-all"
                    >
                      <option value="">Select disposal method</option>
                      <option value="recycling">Recycling</option>
                      <option value="composting">Composting</option>
                      <option value="landfill">Landfill</option>
                      <option value="other">Other</option>
                    </select>
                  </label>
                </div>
              </div>
            </section>

            {/* Submit Button */}
            <div className="mt-12 text-center">
              {submitError && (
                <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg mb-4">
                  {submitError}
                </div>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-[#359381] text-white px-8 py-3 rounded-lg font-medium hover:bg-[#003329] overflow-hidden transform transition-all duration-300 hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Submitting...
                  </div>
                ) : (
                  "Calculate Carbon Footprint"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CateringSurveyPage;
